import React, { useState, useEffect } from "react";
import styled from "styled-components";

const DatesContainer = styled.div`
    position: relative;
    display: inline-block;
    cursor: pointer;
`;

const HoverDates = styled.div`
    position: absolute;
    background: rgba(0, 0, 0, 0.9);
    color: white;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
    white-space: nowrap;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;

    /* Desktop Hover */
    @media (hover: hover) and (pointer: fine) {
        ${DatesContainer}:hover & {
            visibility: visible;
            opacity: 1;
        }
    }

    /* Mobile Toggle */
    ${({ isMobile, showDates }) => isMobile && `
        position: relative;
        bottom: auto;
        left: auto;
        transform: none;
        visibility: ${showDates ? "visible" : "hidden"};
        opacity: ${showDates ? "1" : "0"};
        transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    `}
`;

const FullDatesText = styled.span`
    color: #007bff;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 5px;

    &:hover {
        color: #0056b3;
    }
`;

const KarinaDates = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [showDates, setShowDates] = useState(false);

    useEffect(() => {
        const checkIfMobile = () => {
            setIsMobile("ontouchstart" in window || navigator.maxTouchPoints > 0);
        };

        checkIfMobile();
        window.addEventListener("resize", checkIfMobile);
        return () => window.removeEventListener("resize", checkIfMobile);
    }, []);

    const handleClick = (e) => {
        if (isMobile) {
            e.stopPropagation(); // Prevent unwanted bubbling
            setShowDates(prev => !prev);
        }
    };

    return (
        <>
            <h3>&#128197; Karina's Live Group Healing Dates</h3>
            <ul className="dates">
                <li>Monday 31st March @ 3pm BST / 10am EST / 11am EDT</li>
                <li>Wednesday April 30th @ 3pm BST / 10am EST / 11am EDT</li>
            </ul>

            <DatesContainer>
                <FullDatesText onClick={handleClick}>Full dates</FullDatesText>
                <HoverDates isMobile={isMobile} showDates={showDates}>
                    <ul className="dates">
                        <li>Monday 31st March @ 3pm BST / 10am EDT</li>
                        <li>Wednesday April 30th @ 3pm BST / 10am EDT</li>
                        <li>Friday 10th May @ 3pm BST / 10am EDT</li>
                        <li>Monday 27th May @ 3pm BST / 10am EDT</li>
                        <li>Saturday 15th June @ 3pm BST / 10am EDT</li>
                    </ul>
                </HoverDates>
            </DatesContainer>
        </>
    );
};

export default KarinaDates;
